interface EnvConfigInterface {
	baseApi: string;
	baseWvpUrl: string;
	playWvpKey: string;
	env: string;
	baseThing: string;
	baseIbms: string;
	baseEnergy: string;
}

let hostname = window.location.hostname;

const envMap: { [key: string]: EnvConfigInterface } = {
	dev: {
		baseApi: 'https://api-dev.abtiot.com',
		baseWvpUrl: 'https://wvp.abtiot.com',
		playWvpKey: 'wvp-stream.abtiot.com',
		env: 'dev',
		baseThing: 'http://localhost:8888/#/home',
		baseIbms: `http://localhost:8888/#/operation`,
		baseEnergy: 'http://localhost:8888/#/instrument/bulletinBoard',
	},
	sit: {
		baseApi: 'https://api-dev.abtiot.com',
		baseWvpUrl: 'https://wvp.abtiot.com',
		playWvpKey: 'wvp-stream.abtiot.com',
		env: 'sit',
		baseThing: 'https://thingspace-dev.abtiot.com/#/home',
		baseIbms: 'https://ibms-dev.abtiot.com/#/operation',
		baseEnergy: 'https://energy-dev.abtiot.com/#/instrument/bulletinBoard',
	},
	prod: {
		baseApi: 'https://api.abtiot.com',
		baseWvpUrl: 'https://wvp-prod.abtiot.com',
		playWvpKey: 'wvp-prod-stream.abtiot.com',
		env: 'prod',
		baseThing: 'https://thingspace.abtiot.com/#/home',
		baseIbms: 'https://ibms.abtiot.com/#/operation',
		baseEnergy: 'https://energy.abtiot.com/#/instrument/bulletinBoard',
	},
	local: {
		baseApi: `http://${hostname}:8003`,
		env: 'local',
		baseThing: `http://${hostname}/#/home`,
		baseIbms: `http://${hostname}/#/operation`,
		baseEnergy: `http://${hostname}/#/instrument/bulletinBoard`,
	},
};

let env = 'dev';
var ipRegex = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/;
if (ipRegex.test(hostname)) {
	hostname = 'local';
}

switch (hostname) {
	case 'thingspace-dev.abtiot.com':
	case 'ibms-dev.abtiot.com':
	case 'energy-dev.abtiot.com':
		env = 'sit';
		break;
	case 'thingspace.abtiot.com':
	case 'ibms.abtiot.com':
	case 'energy.abtiot.com':
		env = 'prod';
		break;
	case 'local':
		env = 'local';
		break;
	default:
		env = 'dev';
}

export default envMap[env];
