import { defineStore } from 'pinia';
import { getAuthToken } from '/@/api/login/index';
import img1 from '/@/assets/logo.svg';
import Cookies from 'js-cookie';
import { Session } from '/@/utils/storage';
import md5 from 'js-md5';
let hostname = window.location.hostname;
var ipRegex = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/;
// console.log(hostname, 'hostname')

export const useUserInfo = defineStore('userInfo', {
	state: (): UserInfosState => ({
		userInfos: {
			userName: '',
			photo: '',
			time: 0,
			roles: [],
			authBtnList: [],
			infoData: {},
			projectData: [],
			pid: '',
			uid: '',
      station_id: 0,
		},
		params: {
			username: '',
			type: 2,
			password: '',
			// username: admin@abtnetworks.com,
			// password: 'e10adc3949ba59abbe56e057f20f883e',
			// password: md5('abt123#@!@#'),
		},
	}),
	actions: {
		setUsername(username: any) {
			this.params.username = username;
			setCookie(
				'userName',
				this.params.username,
				['localhost', '127.0.0.1'].includes(document.domain) ? document.domain : document.domain.endsWith(".alicontainer.com") ? '.alicontainer.com' : ipRegex.test(hostname) ? hostname : '.abtiot.com',
				1
			);
		},
		setPassword(password: any) {
			this.params.password = md5(password);
			setCookie(
				'pwd',
				this.params.password,
				['localhost', '127.0.0.1'].includes(document.domain) ? document.domain : document.domain.endsWith(".alicontainer.com") ? '.alicontainer.com' : ipRegex.test(hostname) ? hostname : '.abtiot.com',
				1
			);
		},
		setCode(code: string) {
			setCookie(
				'appCode',
				code,
				['localhost', '127.0.0.1'].includes(document.domain) ? document.domain : document.domain.endsWith(".alicontainer.com") ? '.alicontainer.com' : ipRegex.test(hostname) ? hostname : '.abtiot.com',
				1
			);
		},
		setPid(pid:number) {
			Cookies.set('pid', pid);
		},
    setstationId(stationId: number){
      Cookies.set('station_id', stationId);
    },
		setPro(pro:any) {
			this.userInfos.projectData = pro;
			Session.set('userInfo', this.userInfos);
		},
		async setUserInfos() {
			if (Session.get('userInfo') && Cookies.get('userName')) {
				this.userInfos = Session.get('userInfo');
				Cookies.set('pid', this.userInfos.pid);
				Cookies.set('station_id', this.userInfos.station_id);
			} else {
				const params = {
					username: Cookies.get('userName'),
					type: 2,
					password: Cookies.get('pwd'),
				};
        if(params.username && params.password){
          const userInfos = <UserInfos>await getAuthToken(params);
          if(!userInfos.result.menus){
            Cookies.remove('token');
            Session.clear(); 
            Cookies.remove('userName');
            Cookies.remove('pwd');
            this.params.username = '';
            this.params.password = '';
            return;
          }
          this.userInfos = {
            authBtnList: ['home:list'].concat(userInfos.result && getPermCodes(userInfos.result && userInfos.result.menus)) || [],
            photo: img1,
            roles: [],
            time: new Date().getTime(),
            userName: userInfos.result && userInfos.result.user_info.username,
            projectData: userInfos.result && userInfos.result.projects,
            pid: Cookies.get('pid') || (userInfos.result && userInfos.result.projects[0].pid),
            station_id: Cookies.get('station_id') || (userInfos.result && userInfos.result.projects[0].station_id),
            infoData: userInfos.result,
            uid: userInfos.result && userInfos.result.user_info.uid,
          };
          Cookies.set('uid', this.userInfos.uid);
          Cookies.set('pid', this.userInfos.pid);
          Cookies.set('station_id', this.userInfos.station_id);
          Cookies.set('appCode', 'ibms');
          setCookie(
            'token',
            this.userInfos.infoData.token,
            ['localhost', '127.0.0.1'].includes(document.domain) ? document.domain : document.domain.endsWith(".alicontainer.com") ? '.alicontainer.com' : ipRegex.test(hostname) ? hostname : '.abtiot.com',
            1
          );
          setCookie(
            'tid',
            this.userInfos.infoData.user_info.tid,
            ['localhost', '127.0.0.1'].includes(document.domain) ? document.domain : document.domain.endsWith(".alicontainer.com") ? '.alicontainer.com' : ipRegex.test(hostname) ? hostname : '.abtiot.com',
            1
          );
          Session.set('userInfo', this.userInfos);
        } else {
          Cookies.remove('token');
          Session.clear();
        }
			}
		},
	},
});

// 设置不同域名可访问token 例a.abtiot.com b.abtiot.com 都可访问
function setCookie(key: any, value: any, domain: any, iDay: any) {
	var oDate = new Date();
	oDate.setDate(oDate.getDate() + iDay);
	if (typeof value == 'object') {
		value = JSON.stringify(value);
	}
	value = encodeURIComponent(value);
	document.cookie = key + '=' + value + ';expires=' + oDate + ';domain=' + domain + ';path=/';
}

function getPermCodes(arr: Array<any>): any {
	const result = [];
	for (let item of arr) {
		if (item.perm_code) {
			result.push(item.perm_code);
		}
		if (item.children) {
			result.push(...getPermCodes(item.children));
		}
	}
	return result;
}
