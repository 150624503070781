// 定义内容
export default {
	formI18nLabel: {
		name: 'name',
		email: 'email',
		autograph: 'autograph',
	},
	formI18nPlaceholder: {
		name: 'Please enter your name',
		email: 'Please enter the users Department',
		autograph: 'Please enter the login account name',
	},
};
