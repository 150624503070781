import { RouteRecordRaw } from 'vue-router';

/**
 * 建议：路由 path 路径与文件夹名称相同，找文件可浏览器地址找，方便定位文件位置
 *
 * 路由meta对象参数说明
 * meta: {
 *      title:          菜单栏及 tagsView 栏、菜单搜索名称（国际化）
 *      isLink：        是否超链接菜单，开启外链条件，`1、isLink: 链接地址不为空 2、isIframe:false`
 *        customRouter:   是否开启自定义路由 customRouter:true  为true的时候isLink:链接将生效
 *      isHide：        是否隐藏此路由
 *      isKeepAlive：   是否缓存组件状态
 *      isAffix：       是否固定在 tagsView 栏上
 *      isIframe：      是否内嵌窗口，开启条件，`1、isIframe:true 2、isLink：链接地址不为空`
 *      roles：         当前路由权限标识，取角色管理。控制路由显示、隐藏。超级管理员：admin 普通角色：common
 *      icon：          菜单、tagsView 图标，阿里：加 `iconfont xxx`，fontawesome：加 `fa xxx`
 * }
 */

// 扩展 RouteMeta 接口
declare module 'vue-router' {
	interface RouteMeta {
		title?: string;
		isLink?: string;
		customRouter?: boolean;
		isHide?: boolean;
		isKeepAlive?: boolean;
		isAffix?: boolean;
		isIframe?: boolean;
		roles?: string[];
		icon?: string;
	}
}

/**
 * 定义动态路由
 * 前端添加路由，请在顶级节点的 `children 数组` 里添加
 * @description 未开启 isRequestRoutes 为 true 时使用（前端控制路由），开启时第一个顶级 children 的路由将被替换成接口请求回来的路由数据
 * @description 各字段请查看 `/@/views/system/menu/component/addMenu.vue 下的 ruleForm`
 * @returns 返回路由菜单数据
 */
export const dynamicRoutesA: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: '/',
		component: () => import('/@/layout/index.vue'),
		redirect: '/home',
		meta: {
			isKeepAlive: true,
		},
		children: [
			{
				path: '/home',
				name: 'home',
				component: () => import('/@/views/home/index.vue'),
				meta: {
					title: '首页',
					isKeepAlive: true,
					isAffix: true,
					authCodes: ['home:list'],
					icon: 'iconfont icon-shouye',
				},
			},
			{
				path: '/policy',
				name: 'policy',
				component: () => import('/@/layout/routerView/parent.vue'),
				redirect: '/policy/lightIndex',
				meta: {
					title: '策略管理',
					isKeepAlive: true,
					authCodes: ['strategy'],
					icon: 'ele-Sell',
				},
				children: [
					{
						path: '/policy/lightIndex',
						name: 'lightIndex',
						component: () => import('/@/views/policyManage/lightIndex.vue'),
						meta: {
							title: '照明策略',
							isKeepAlive: true,
							authCodes: ['strategy:light'],
						},
					},
					{
						path: '/policy/hvacIndex',
						name: 'hvacIndex',
						component: () => import('/@/views/policyManage/hvacIndex.vue'),
						meta: {
							title: '暖通策略',
							isKeepAlive: true,
							authCodes: ['strategy:bim'],
						},
					},
					// {
					// 	path: '/policy/energyIndex',
					// 	name: 'energyIndex',
					// 	component: () => import('/@/views/policyManage/energyIndex.vue'),
					// 	meta: {
					// 		title: '能源策略',
					// 		isKeepAlive: true,
					// 		authCodes: ['strategy:energy'],
					// 	},
					// },
				],
			},
			{
				path: '/operation',
				name: 'operationone',
				component: () => import('/@/layout/routerView/parent.vue'),
				redirect: '/operation/operManage',
				meta: {
					title: '运行管理',
					isKeepAlive: true,
					authCodes: ['working'],
					icon: 'iconfont icon-neiqianshujuchucun',
				},
				children: [
					{
						path: '/operation/operManage',
						name: 'operManage',
						component: () => import('/@/views/operations/operDevManage/index.vue'),
						meta: {
							title: '智慧照明',
							isKeepAlive: true,
							authCodes: ['working:smartlight'],
						},
					},
					{
						path: '/operation/equipmentManagement',
						name: 'equipmentManagement',
						component: () => import('/@/views/hvacControi/equipmentManagement/index.vue'),
						meta: {
							title: '智慧暖通',
							isKeepAlive: true,
							authCodes: ['working:smartbim'],
						},
					},
					{
						path: '/iotddc',
						name: 'iotddc',
						component: () => import('/@/views/operation/iotddc/index.vue'),
						meta: {
							title: '变配电',
							isKeepAlive: true,
							authCodes: ['energy'],
						},
					},
				],
			},
			{
				path: '/energy',
				name: 'energy',
				component: () => import('/@/layout/routerView/parent.vue'),
				redirect: '/instrument/dataAdministration',
				meta: {
					title: '能源管理',
					isKeepAlive: true,
					authCodes: ['energy'],
					icon: 'iconfont icon-xingqiu',
				},
				children: [
					{
						path: '/instrument/dataAdministration',
						name: 'dataAdministration',
						component: () =>
							import('/@/views/instrument/dataAdministration/baseManagement/index.vue'),
						meta: {
							title: '用能采集',
							isKeepAlive: true,
							authCodes: ['energy:collect'],
						},
						redirect: '/instrument/dataAdministration/baseManagement',
						children: [
							{
								path: '/instrument/dataAdministration/baseManagement',
								name: '/dataAdministration/baseManagement',
								component: () =>
									import('/@/views/instrument/dataAdministration/baseManagement/index.vue'),
								meta: {
									title: '底数管理',
									isKeepAlive: true,
									authCodes: ['energy:collect:basenumber'],
								},
							},
							{
								path: '/instrument/dataAdministration/energyConsumptionDetails',
								name: '/dataAdministration/energyConsumptionDetails',
								component: () =>
									import(
										'/@/views/instrument/dataAdministration/energyConsumptionDetails/index.vue'
									),
								meta: {
									title: '能耗明细',
									isKeepAlive: true,
									authCodes: ['energy:collect:energydetail'],
								},
							},
						],
					},
					{
						path: '/instrument/config',
						name: 'config',
						component: () => import('/@/layout/routerView/parent.vue'),
						meta: {
							title: '仪表配置',
							isKeepAlive: true,
							authCodes: ['energy:Instrument'],
						},
						redirect: '/instrument/SubItemManagement',
						children: [
							{
								path: '/instrument/SubItemManagement',
								name: 'SubItemManagement',
								component: () => import('/@/views/instrument/SubItemManagement/index.vue'),
								meta: {
									title: '分项管理',
									isKeepAlive: true,
									authCodes: ['energy:Instrument:subitem'],
								},
							},
							{
								path: '/instrument/synchronous',
								name: 'synchronous',
								component: () => import('/@/views/instrument/synchronous/index.vue'),
								meta: {
									title: '设备管理',
									isKeepAlive: true,
									authCodes: ['energy:Instrument:devicemanager'],
								},
							},
						],
					},
					{
						path: '/instrument/energeAnalysis/indexBoard',
						name: '/energeAnalysis/indexBoard',
						component: () => import('/@/views/instrument/energeAnalysis/analysisBoard/index.vue'),
						meta: {
							title: '用能分析',
							isKeepAlive: true,
							authCodes: ['energy:analysis'],
						},
					},
					{
						path: '/operation/energeAnalysis',
						name: 'hvacControiPage',
						component: () => import('/@/layout/routerView/parent.vue'),
						meta: {
							title: '目标管理',
							isKeepAlive: true,
							authCodes: ['energy:traget'],
						},
						redirect: '/instrument/energeAnalysis/budManage',
						children: [
							{
								path: '/instrument/energeAnalysis/budManage',
								name: '/energeAnalysis/budManage',
								component: () => import('/@/views/instrument/energeAnalysis/budgetManage.vue'),
								meta: {
									title: '用能预算管理',
									isKeepAlive: true,
									authCodes: ['energy:traget:budget'],
								},
							},
							{
								path: '/instrument/energeAnalysis/indicator',
								name: '/energeAnalysis/indicator',
								component: () => import('/@/views/instrument/energeAnalysis/indicatorManage.vue'),
								meta: {
									title: '用能指标管理',
									isKeepAlive: true,
									authCodes: ['energy:traget:index'],
								},
							},
						],
					},
				],
			},
			{
				path: '/digitalTwinPage',
				name: 'digitalTwinPage',
				component: () => import('/@/layout/routerView/link.vue'),
				meta: {
					title: '数字孪生',
					isLink: '/digitalTwin',
					isKeepAlive: false,
					isIframe: false,
					authCodes: ['digital'],
					icon: 'iconfont icon-ico_shuju',
				},
			},
			{
				path: '/operation/security',
				name: 'security',
				component: () => import('/@/layout/routerView/link.vue'),
				meta: {
					title: '安防管理',
					isLink: 'http://192.168.110.201/portal/',
					isKeepAlive: false,
					isIframe: false,
					authCodes: ['security'],
					icon: 'iconfont icon-quanxian',
				},
			},
			{
				path: '/operation/protectionModule',
				name: 'protectionModule',
				component: () => import('/@/views/fireFighting/protectionModule/index.vue'),
				meta: {
					title: '消防管理',
					isKeepAlive: true,
					authCodes: ['fire'],
					icon: 'iconfont icon-zidingyibuju',
				},
			},
			// 暂定
			// {
			// 	path: '/instrument/alarmManage',
			// 	name: 'alarmManage',
			// 	component: () => import('/@/views/instrument/alarmManage/index.vue'),
			// 	meta: {
			// 		title: '用能预警',
			// 		isKeepAlive: true,
			// 		authCodes: ['alarm'],
			// 		icon: 'ele-SetUp',
			// 	},
			// },
			{
				name: 'product',
				path: '/product',
				component: () => import('/@/views/product/index.vue'),
				meta: {
					title: '产品管理',
					isKeepAlive: true,
					authCodes: ['product'],
					icon: 'iconfont icon-crew_feature',
				},
			},
			{
				path: '/device',
				name: 'deviceIndex',
				component: () => import('/@/views/deviceManage/index.vue'),
				meta: {
					title: '设备管理',
					isKeepAlive: true,
					authCodes: ['device'],
					icon: 'iconfont icon-gongju',
				},
			},
			{
				path: '/subRules',
				name: 'subRulesIndex',
				component: () => import('/@/views/subRules/index.vue'),
				meta: {
					title: '数据流转',
					isKeepAlive: true,
					authCodes: ['data'],
					icon: 'ele-Connection',
				},
			},
			{
				path: '/project',
				name: 'projectIndex',
				component: () => import('/@/views/projectManage/index.vue'),
				meta: {
					title: '项目管理',
					isKeepAlive: true,
					authCodes: ['project'],
					icon: 'iconfont icon-dongtai',
				},
			},
			{
				path: '/rights',
				name: 'rightsIndex',
				component: () => import('/@/layout/routerView/parent.vue'),
				redirect: '/rights/rightsIndex',
				meta: {
					title: '系统管理',
					isKeepAlive: true,
					authCodes: ['system'],
					icon: 'iconfont icon-gerenzhongxin',
				},
				children: [
					{
						path: '/rights/rightsIndex',
						name: 'rightsIndex',
						component: () => import('/@/views/rightsManage/index.vue'),
						meta: {
							title: '用户管理',
							isKeepAlive: true,
							authCodes: ['system:usermanager'],
						},
					},
					{
						path: '/rights/rightsRoles',
						name: 'rightsRoles',
						component: () => import('/@/views/rightsManage/indexRoles.vue'),
						meta: {
							title: '角色管理',
							isKeepAlive: true,
							authCodes: ['system:rolemanager'],
						},
					},
				],
			},
		],
	},
];

// 客户端路由(底座)
export const dynamicRoutes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: '/',
		component: () => import('/@/layout/index.vue'),
		redirect: '/home',
		meta: {
			isKeepAlive: true,
		},
		children: [
			{
				path: '/home',
				name: 'home',
				component: () => import('/@/views/home/index.vue'),
				meta: {
					title: '订阅应用',
					isKeepAlive: true,
					isAffix: true,
					authCodes: ['home:list'],
					icon: 'iconfont icon-shouye',
				},
			},
			{
				name: 'product',
				path: '/product',
				component: () => import('/@/views/product/index.vue'),
				meta: {
					title: '产品管理',
					isKeepAlive: true,
					authCodes: ['product'],
					icon: 'iconfont icon-crew_feature',
				},
			},
			{
				path: '/device',
				name: 'deviceIndex',
				component: () => import('/@/views/deviceManage/index.vue'),
				meta: {
					title: '设备管理',
					isKeepAlive: true,
					authCodes: ['device'],
					icon: 'iconfont icon-gongju',
				},
			},
			{
				path: '/IOTAlarmManagement',
				name: 'IOTAlarm',
				component: () => import('/@/views/IOTAlarmManagement/AlarmRules.vue'),
				meta: {
					title: '告警管理',
					authCodes: ['iotalarm'],
					icon: 'ele-SetUp',
				},
				redirect: '/IOTAlarmManagement/AlarmRules',
				children: [
					{
						path: '/IOTAlarmManagement/AlarmRules',
						name: 'AlarmRules',
						component: () => import('/@/views/IOTAlarmManagement/AlarmRules.vue'),
						meta: {
							title: '告警规则',
							isKeepAlive: false,
							authCodes: ['iotalarm:rule'],
						},
					},
					{
						path: '/IOTAlarmManagement/AlarmLog',
						name: 'AlarmLog',
						component: () => import('/@/views/IOTAlarmManagement/AlarmLog.vue'),
						meta: {
							title: '告警记录',
							isKeepAlive: false,
							authCodes: ['iotalarm:record'],
						},
					},
				],
			},
			{
				path: '/subRules',
				name: 'subRulesIndex',
				component: () => import('/@/views/subRules/index.vue'),
				meta: {
					title: '数据流转',
					isKeepAlive: true,
					authCodes: ['data'],
					icon: 'ele-Connection',
				},
			},
			{
				path: '/project',
				name: 'projectIndex',
				component: () => import('/@/views/projectManage/index.vue'),
				meta: {
					title: '项目管理',
					isKeepAlive: true,
					authCodes: ['project'],
					icon: 'iconfont icon-dongtai',
				},
			},
			{
				path: '/rights',
				name: 'rightsIndex',
				component: () => import('/@/layout/routerView/parent.vue'),
				redirect: '/rights/rightsIndex',
				meta: {
					title: '系统管理',
					isKeepAlive: true,
					authCodes: ['system'],
					icon: 'iconfont icon-gerenzhongxin',
				},
				children: [
					{
						path: '/rights/rightsIndex',
						name: 'rightsIndex',
						component: () => import('/@/views/rightsManage/index.vue'),
						meta: {
							title: '用户管理',
							isKeepAlive: true,
							authCodes: ['system:usermanager'],
						},
					},
					{
						path: '/rights/rightsRoles',
						name: 'rightsRoles',
						component: () => import('/@/views/rightsManage/indexRoles.vue'),
						meta: {
							title: '角色管理',
							isKeepAlive: true,
							authCodes: ['system:rolemanager'],
						},
					},
				],
			},
		],
	},
];

// IBMS应用端路由
export const ibmsRoutes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: '/',
		component: () => import('/@/layout/index.vue'),
		redirect: '/operation',
		meta: {
			isKeepAlive: true,
		},
		children: [
			{
				path: '/operation',
				name: 'operation',
				component: () => import('/@/views/home/indexPage.vue'),
				meta: {
					title: '首页',
					isKeepAlive: true,
					isAffix: true,
					authCodes: ['dashboard'],
					icon: 'ele-OfficeBuilding',
				},
			},
			{
				path: '/operManage',
				name: 'operationone',
				component: () => import('/@/layout/routerView/parent.vue'),
				redirect: '/operation/operManage',
				meta: {
					title: '运行管理',
					isKeepAlive: true,
					authCodes: ['working'],
					icon: 'iconfont icon-neiqianshujuchucun',
				},
				children: [
					{
						path: '/operation/operManage',
						name: 'operManage',
						component: () => import('/@/views/operations/operDevManage/index.vue'),
						meta: {
							title: '智慧照明',
							isKeepAlive: true,
							authCodes: ['working:smartlight'],
						},
					},
					{
						path: '/operation/equipmentManagement',
						name: 'equipmentManagement',
						component: () => import('/@/views/hvacControi/equipmentManagement/index.vue'),
						meta: {
							title: '智慧暖通',
							isKeepAlive: true,
							authCodes: ['working:smartbim'],
						},
					},
					{
						path: '/operation/iotddc',
						name: 'iotddc',
						component: () => import('/@/layout/routerView/parent.vue'),
						meta: {
							title: '变配电',
							isKeepAlive: true,
							authCodes: ['working:power'],
						},
						redirect: '/operation/iotddc/equipmentManagement',
						children: [
							{
								path: '/operation/iotddc/equipmentManagement',
								name: 'iotddcs',
								component: () => import('/@/views/operation/iotddc/equipmentManagement.vue'),
								meta: {
									title: '设备管理',
									isKeepAlive: true,
									authCodes: ['working:power:device'],
								},
							},
							{
								path: '/operation/iotddc/operationMonitoring',
								name: 'operationMonitoring',
								component: () => import('/@/views/operation/iotddc/operationMonitoring.vue'),
								meta: {
									title: '运行监控',
									isKeepAlive: true,
									authCodes: ['working:power:monitor'],
								},
							},
						],
					},
					{
						path: '/operation/photovoltaic',
						name: 'photovoltaic',
						component: () => import('/@/layout/routerView/parent.vue'),
						meta: {
							title: '光伏',
							isKeepAlive: true,
							authCodes: ['working:pv'],
						},
						redirect: '/operation/photovoltaic',
						children: [
							{
								path: '/operation/photovoltaic',
								name: 'photovoltaic',
								component: () => import('/@/views/hvacControi/photovoltaic/index.vue'),
								meta: {
									title: '设备管理',
									isKeepAlive: true,
									authCodes: ['working:pv'],
								},
							},
							{
								path: '/operation/monitoring',
								name: 'monitoring',
								component: () => import('/@/views/hvacControi/photovoltaic/monitoringIndex.vue'),
								meta: {
									title: '运行监测',
									isKeepAlive: true,
									authCodes: ['working:pv'],
								},
							},
						],
					},
					{
						path: '/operation/protectionModule/EquipmentMonitoring',
						name: 'EquipmentMonitoring',
						component: () =>
							import('/@/views/fireFighting/protectionModule/EquipmentMonitoring.vue'),
						meta: {
							title: '消防监控',
							isKeepAlive: true,
							authCodes: ['fire:monitor'],
						},
					},
				],
			},
			{
				path: '/operation/policy',
				name: 'policy',
				component: () => import('/@/layout/routerView/parent.vue'),
				redirect: '/operation/policy/baseConfig',
				meta: {
					title: '策略管理',
					isKeepAlive: true,
					authCodes: ['strategy'],
					icon: 'ele-Sell',
				},
				children: [
          {
						path: '/operation/policy/baseConfig',
						name: 'baseConfig',
						component: () => import('/@/views/policyManage/baseConfig.vue'),
						meta: {
							title: '基本配置',
							isKeepAlive: false,
							authCodes: ['strategy:baseconfig'],
						},
					},
					{
						path: '/operation/policy/lightIndex',
						name: 'lightIndex',
						component: () => import('/@/views/policyManage/lightIndex.vue'),
						meta: {
							title: '照明策略',
							isKeepAlive: true,
							authCodes: ['strategy:light'],
						},
					},
					{
						path: '/operation/policy/hvacIndex',
						name: 'hvacIndex',
						component: () => import('/@/views/policyManage/hvacIndex.vue'),
						meta: {
							title: '暖通策略',
							isKeepAlive: true,
							authCodes: ['strategy:bim'],
						},
					},
				],
			},
			{
				path: '/operation/instrument/dataAdministration',
				name: 'dataAdministration',
				component: () => import('/@/views/instrument/dataAdministration/baseManagement/index.vue'),
				meta: {
					title: '用能采集',
					isKeepAlive: true,
					authCodes: ['collect'],
					icon: 'iconfont icon-xuanzeqi',
				},
				redirect: '/operation/instrument/dataAdministration/baseManagement',
				children: [
					{
						path: '/operation/instrument/dataAdministration/baseManagement',
						name: '/dataAdministration/baseManagement',
						component: () =>
							import('/@/views/instrument/dataAdministration/baseManagement/index.vue'),
						meta: {
							title: '底数管理',
							isKeepAlive: true,
							authCodes: ['collect:basenumber'],
						},
					},
					{
						path: '/operation/instrument/dataAdministration/energyConsumptionDetails',
						name: '/dataAdministration/energyConsumptionDetails',
						component: () =>
							import('/@/views/instrument/dataAdministration/energyConsumptionDetails/index.vue'),
						meta: {
							title: '能耗明细',
							isKeepAlive: true,
							authCodes: ['collect:energydetail'],
						},
					},
				],
			},
			{
				path: '/operation/energyModel',
				name: 'energyModel',
				component: () => import('/@/layout/routerView/parent.vue'),
				meta: {
					title: '能耗模型',
					isKeepAlive: true,
					authCodes: ['emodel'],
					icon: 'ele-Odometer',
				},
				redirect: '/operation/instrument/SubItemManagement',
				children: [
					{
						path: '/operation/instrument/SubItemManagement',
						name: 'SubItemManagement',
						component: () => import('/@/views/instrument/SubItemManagement/index.vue'),
						meta: {
							title: '分项模型',
							isKeepAlive: true,
							authCodes: ['emodel:subitem'],
						},
					},
					{
						path: '/operation/instrument/resourceManage',
						name: 'resourceManage',
						component: () => import('/@/views/instrument/resourceManage/index.vue'),
						meta: {
							title: '资源位管理',
							isKeepAlive: true,
							authCodes: ['emodel:resbit'],
						},
					},
					{
						path: '/operation/instrument/synchronous',
						name: 'synchronous',
						component: () => import('/@/views/instrument/synchronous/index.vue'),
						meta: {
							title: '用能仪表配置',
							isKeepAlive: true,
							authCodes: ['emodel:Instrument'],
						},
					},
				],
			},
			{
				path: '/operation/energeAnalysis',
				name: 'hvacControiPage',
				component: () => import('/@/layout/routerView/parent.vue'),
				meta: {
					title: '目标管理',
					isKeepAlive: true,
					authCodes: ['traget'],
					icon: 'iconfont icon-biaodan',
				},
				redirect: '/operation/instrument/energeAnalysis/budManage',
				children: [
					{
						path: '/operation/instrument/energeAnalysis/controlBudget',
						name: '/energeAnalysis/control',
						component: () => import('/@/views/instrument/energeAnalysis/controlBudget.vue'),
						meta: {
							title: '预算管控',
							isKeepAlive: true,
							authCodes: ['traget:control'],
						},
					},
					{
						path: '/operation/instrument/energeAnalysis/usageAnalysis',
						name: '/energeAnalysis/usageAnalysis',
						component: () => import('/@/views/instrument/energeAnalysis/usageAnalysis.vue'),
						meta: {
							title: '预算使用分析',
							isKeepAlive: true,
							authCodes: ['traget:apply'],
						},
					},
				],
			},
			{
				path: '/operation/instrument/energeAnalysis/indexBoard',
				name: '/energeAnalysis/indexBoard',
				component: () => import('/@/views/instrument/energeAnalysis/analysisBoard/index.vue'),
				meta: {
					title: '用能分析',
					isKeepAlive: true,
					authCodes: ['eanalysis'],
					icon: 'iconfont icon-shuju',
				},
				redirect: '/operation/energyAnalysis/trendAnalysis',
				children: [
					// {
					// 	path: '/operation/energyAnalysis/budgetCompletionRate',
					// 	name: 'budgetCompletionRate',
					// 	component: () => import('/@/views/energyAnalysis/budgetCompletionRate.vue'),
					// 	meta: {
					// 		title: '预算完成率分析',
					// 		isKeepAlive:false,
					// 		authCodes: ['eanalysis:trend'],
					// 	},
					// },
					// {
					// 	path: '/operation/energyAnalysis/yearAnalysis',
					// 	name: 'yearAnalysis',
					// 	component: () => import('/@/views/energyAnalysis/yearAnalysis.vue'),
					// 	meta: {
					// 		title: '同比分析',
					// 		isKeepAlive: true,
					// 		authCodes: ['eanalysis:trend'],
					// 	},
					// },
					// {
					// 	path: '/operation/energyAnalysis/monthAnalysis',
					// 	name: 'monthAnalysis',
					// 	component: () => import('/@/views/energyAnalysis/monthAnalysis.vue'),
					// 	meta: {
					// 		title: '环比分析',
					// 		isKeepAlive: true,
					// 		authCodes: ['eanalysis:trend'],
					// 	},
					// },
					// {
					// 	path: '/operation/energyAnalysis/comparativeAnalysis',
					// 	name: 'comparativeAnalysis',
					// 	component: () => import('/@/views/energyAnalysis/comparativeAnalysis.vue'),
					// 	meta: {
					// 		title: '对比分析',
					// 		isKeepAlive: true,
					// 		authCodes: ['eanalysis:trend'],
					// 	},
					// },
					{
						path: '/operation/energyAnalysis/trendAnalysis',
						name: 'trendAnalysis',
						component: () => import('/@/views/energyAnalysis/trendAnalysis.vue'),
						meta: {
							title: '趋势分析',
							isKeepAlive: true,
							authCodes: ['eanalysis:trend'],
						},
					},
					{
						path: '/operation/energyAnalysis/analysisBoard',
						name: 'analysisBoard',
						component: () =>
							import('/@/views/instrument/energeAnalysis/analysisBoard/components/energyFlow.vue'),
						meta: {
							title: '能流图',
							isKeepAlive: true,
							authCodes: ['eanalysis:flow'],
						},
					},
					// {
					// 	path: '/operation/energyAnalysis/energyType',
					// 	name: 'energyType',
					// 	component: () => import('/@/views/energyAnalysis/energyType.vue'),
					// 	meta: {
					// 		title: '能耗类型分布',
					// 		isKeepAlive: true,
					// 		authCodes: ['eanalysis:trend'],
					// 	},
					// },
				],
			},
			/*{
                path: '/operation/security',
                name: 'security',
                component: () => import('/@/layout/routerView/link.vue'),
                meta: {
                    title: '安防管理',
                    isLink: 'http://192.168.110.201/portal/',
                    isKeepAlive: false,
                    isIframe: false,
                    authCodes: ['security'],
                    icon: 'iconfont icon-quanxian',
                },
            },*/
			{
				path: '/operation/security',
				name: 'security',
				component: () => import('/@/layout/routerView/parent.vue'),
				meta: {
					title: '安防管理',
					isKeepAlive: true,
					authCodes: ['security'],
					icon: 'iconfont icon-quanxian',
				},
				redirect: '/operation/energyAnalysis/videoMonitoring',
				children: [
					{
						path: '/operation/security',
						name: 'security',
						component: () => import('/@/layout/routerView/link.vue'),
						meta: {
							title: '综合安防平台',
							isLink: 'http://192.168.110.201/portal/',
							isKeepAlive: false,
							isIframe: false,
							authCodes: ['security:composite'],
						},
					},
					{
						path: '/operation/energyAnalysis/videoMonitoring',
						name: 'videoMonitoring',
						component: () => import('/@/layout/routerView/parent.vue'),
						meta: {
							title: '充电桩安防',
							isKeepAlive: true,
							authCodes: ['security:charge'],
						},
						redirect: '/operation/energyAnalysis/videoMonitoring',
						children: [
							{
								path: '/operation/energyAnalysis/videoMonitoring',
								name: 'videoMonitoring',
								component: () => import('/@/views/videoMonitoring/index.vue'),
								meta: {
									title: '视频监控',
									isKeepAlive: true,
									authCodes: ['security:charge:video'],
								},
							},
						],
					},
				],
			},
			// {
			// 	path: '/operation/protectionModule',
			// 	name: 'protectionModule',
			// 	component: () => import('/@/layout/routerView/parent.vue'),
			// 	meta: {
			// 		title: '消防管理',
			// 		isKeepAlive: true,
			// 		authCodes: ['fire'],
			// 		icon: 'iconfont icon-zidingyibuju',
			// 	},
			// 	redirect: '/operation/protectionModule/EquipmentMonitoring',
			// 	children: [
			// 		{
			// 			path: '/operation/protectionModule/EquipmentMonitoring',
			// 			name: 'EquipmentMonitoring',
			// 			component: () => import('/@/views/fireFighting/protectionModule/EquipmentMonitoring.vue'),
			// 			meta: {
			// 				title: '设备监控',
			// 				isKeepAlive: true,
			//         authCodes: ['fire:monitor'],
			// 			},
			// 		},
			// 		{
			// 			path: '/operation/protectionModule/alarmRecord',
			// 			name: 'alarmRecord',
			// 			component: () => import('/@/views/fireFighting/protectionModule/alarmRecord.vue'),
			// 			meta: {
			// 				title: '报警记录',
			// 				isKeepAlive: true,
			//         authCodes: ['fire:record'],
			// 			},
			// 		},
			//     // 思召暂留
			// 		{
			// 			path: '/operation/protectionModule/model',
			// 			name: 'protectionModules',
			// 			component: () => import('/@/views/fireFighting/protectionModule/index.vue'),
			// 			meta: {
			// 				title: '消防模型',
			// 				isKeepAlive: true,
			// 				isHide: true,
			//         authCodes: ['fire:record'],
			// 			},
			// 		},
			// 	]
			// },
			{
				path: '/operation/TSAlarmManagement',
				name: 'TSAlarmRules',
				component: () => import('/@/layout/routerView/parent.vue'),
				meta: {
					title: '告警管理',
					isKeepAlive: false,
					icon: 'ele-SetUp',
					authCodes: ['tsalarm'],
				},
				redirect: '/operation/TSAlarmManagement/TSAlarmRules',
				children: [
					{
						path: '/operation/TSAlarmManagement/TSAlarmRules',
						name: 'TSAlarmRules',
						component: () => import('/@/views/TSAlarmManagement/TSAlarmRules.vue'),
						meta: {
							title: '告警规则',
							isKeepAlive: false,
							authCodes: ['tsalarm:rule'],
						},
					},
					{
						path: '/operation/TSAlarmManagement/TSAlarmLog',
						name: 'TSAlarmLog',
						component: () => import('/@/views/TSAlarmManagement/TSAlarmLog.vue'),
						meta: {
							title: '告警记录',
							isKeepAlive: false,
							authCodes: ['tsalarm:record'],
						},
					},
				],
			},
			{
				path: '/operation/visualManagement/parameterConfig',
				name: 'defaultTouchScreen',
				component: () => import('/@/layout/routerView/parent.vue'),
				meta: {
					title: '可视化管理',
					isKeepAlive: true,
					authCodes: ['viewmanager'],
					icon: 'ele-SetUp',
				},
				children: [
          {
						path: '/operation/visualManagement/parameterConfig',
						name: 'parameterConfig',
						component: () => import('/@/views/visualManagement/parameterConfig.vue'),
						meta: {
							title: '参数配置',
							isKeepAlive: false,
							isIframe: false,
							authCodes: ['viewmanager:paramconfig'],
						},
					},
					{
						path: '/operation/defaultTouchScreen',
						name: 'defaultTouchScreen',
						component: () => import('/@/views/visualManagement/defaultTouchScreen.vue'),
						meta: {
							title: '触控屏',
							isKeepAlive: false,
							isIframe: false,
							authCodes: ['viewmanager:screen'],
						},
					},
				],
			},
			{
				path: '/operation/touchScreen',
				name: 'touchScreen',
				component: () => import('/@/layout/routerView/link.vue'),
				meta: {
					title: '4楼触控屏',
					isLink: '/touchScreen',
					isKeepAlive: false,
					isIframe: false,
					authCodes: ['digital'],
					icon: 'iconfont icon-ico_shuju',
				},
			},
			{
				path: '/operation/touchScreenLink',
				name: 'touchScreenLink',
				component: () => import('/@/layout/routerView/link.vue'),
				meta: {
					title: '5楼触控屏',
					isLink: '/touchScreenLink',
					isKeepAlive: false,
					isIframe: false,
					authCodes: ['digital'],
					icon: 'iconfont icon-ico_shuju',
				},
			},
			// 暂定
			// {
			// 	path: '/operation/policy/energyIndex1',
			// 	name: "energyIndex1",
			// 	component: () => import('/@/views/policyManage/energyIndex.vue'),
			// 	meta: {
			// 		title: '运维管理',
			// 		isKeepAlive: true,
			// 		authCodes: ['operation'],
			// 		icon: 'iconfont icon-shuxingtu',
			// 	}
			// },
		],
	},
];
// 数字孪生路由
export const digitalTwinRoutes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: '/',
		component: () => import('/@/layout/index.vue'),
		redirect: '/digitalTwin',
		meta: {
			isKeepAlive: true,
		},
		children: [
			{
				path: '/digitalTwin',
				name: 'digitalTwin',
				component: () => import('/@/views/digitalTwin/index.vue'),
				meta: {
					title: '数字孪生',
					isKeepAlive: true,
					isAffix: true,
					authCodes: ['digitaltwin'],
					icon: 'ele-OfficeBuilding',
				},
			},
		],
	},
];
// 能源管理路由
export const mentRoutes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: '/',
		component: () => import('/@/layout/index.vue'),
		redirect: '/instrument/bulletinBoard',
		meta: {
			isKeepAlive: true,
		},
		children: [
			{
				path: '/instrument/bulletinBoard',
				name: 'bulletinBoard',
				component: () => import('/@/views/instrument/bulletinBoard/index.vue'),
				meta: {
					title: '首页看板',
					isKeepAlive: true,
					authCodes: ['home:list'],
				},
			},
			{
				path: '/instrument/SubItemManagement',
				name: 'SubItemManagement',
				component: () => import('/@/views/instrument/SubItemManagement/index.vue'),
				meta: {
					title: '分项管理',
					isKeepAlive: true,
					authCodes: ['home:list'],
				},
			},
			{
				path: '/instrument/synchronous',
				name: 'synchronous',
				component: () => import('/@/views/instrument/synchronous/index.vue'),
				meta: {
					title: '设备管理',
					isKeepAlive: true,
					authCodes: ['home:list'],
				},
			},
			{
				path: '/instrument/dataAdministration',
				name: 'dataAdministration',
				component: () => import('/@/views/instrument/dataAdministration/baseManagement/index.vue'),
				meta: {
					title: '数据管理',
					isKeepAlive: true,
					authCodes: ['home:list'],
				},
				redirect: '/instrument/dataAdministration/baseManagement',
				children: [
					{
						path: '/instrument/dataAdministration/baseManagement',
						name: '/dataAdministration/baseManagement',
						component: () =>
							import('/@/views/instrument/dataAdministration/baseManagement/index.vue'),
						meta: {
							title: '底数管理',
							isKeepAlive: true,
							authCodes: ['home:list'],
						},
					},
					{
						path: '/instrument/dataAdministration/energyConsumptionDetails',
						name: '/dataAdministration/energyConsumptionDetails',
						component: () =>
							import('/@/views/instrument/dataAdministration/energyConsumptionDetails/index.vue'),
						meta: {
							title: '能耗明细',
							isKeepAlive: true,
							authCodes: ['home:list'],
						},
					},
				],
			},
			// {
			// 	path: '/instrument/ltemizedStatistics',
			// 	name: 'ltemizedStatistics',
			// 	component: () => import('/@/views/instrument/ltemizedStatistics/index.vue'),
			// 	meta: {
			// 		title: '分项统计',
			// 		isKeepAlive: true,
			// 		authCodes: ['home:list'],
			// 	},
			// },
		],
	},
];

/**
 * 定义404、401界面
 * @link
 */
export const notFoundAndNoPower = [
	{
		path: '/:path(.*)*',
		name: 'notFound',
		component: () => import('/@/views/error/404.vue'),
		meta: {
			title: 'message.staticRoutes.notFound',
			isHide: true,
		},
	},
	{
		path: '/401',
		name: 'noPower',
		component: () => import('/@/views/error/401.vue'),
		meta: {
			title: 'message.staticRoutes.noPower',
			isHide: true,
		},
	},
];

/**
 * 定义静态路由（默认路由）
 * 此路由不要动，前端添加路由的话，请在 `dynamicRoutes 数组` 中添加
 * @description 前端控制直接改 dynamicRoutes 中的路由，后端控制不需要修改，请求接口路由数据时，会覆盖 dynamicRoutes 第一个顶级 children 的内容（全屏，不包含 layout 中的路由出口）
 * @returns 返回路由菜单数据
 */

export const staticRoutes: Array<RouteRecordRaw> = [
	{
		path: '/login',
		name: 'login',
		component: () => import('/@/views/login/index.vue'),
		meta: {
			title: '登录',
		},
	},
	{
		path: '/dialog/:id',
		name: 'dialog',
		component: () => import('/@/views/fireFighting/components/Dialog.vue'),
	},
	{
		path: '/digitalTwin',
		name: 'digitalTwin',
		component: () => import('/@/views/digitalTwin/index.vue'),
		meta: {
			title: '数字孪生',
		},
	},
	{
		path: '/touchScreen',
		name: 'touchScreen',
		component: () => import('/@/views/visualManagement/touchScreen.vue'),
		meta: {
			title: '4楼触控屏',
		},
	},
	{
		path: '/touchScreenLink',
		name: 'touchScreenLink',
		component: () => import('/@/views/visualManagement/touchScreenLink.vue'),
		meta: {
			title: '5楼触控屏',
		},
	},
];
