import axios, { AxiosInstance } from 'axios';
import { ElMessage } from 'element-plus';
import { Session } from '/@/utils/storage';
import qs from 'qs';
import envData from './env';

const { baseApi } = envData;
import Cookies from 'js-cookie';

// 配置新建一个 axios 实例
const service: AxiosInstance = axios.create({
	baseURL: process.env.NODE_ENV === 'development' ? '' : baseApi,
	timeout: 50000,
	headers: {
		'Content-Type': 'application/json',
	},
	paramsSerializer: {
		serialize(params) {
			return qs.stringify(params, { allowDots: true });
		},
	},
});

// 添加请求拦截器
service.interceptors.request.use(
	(config) => {
		// 在发送请求之前做些什么 token
		if (Session.get('token')) {
			config.headers!['token'] = `${Session.get('token')}`;
			config.headers!['Authorization'] = `${Session.get('token')}`;
		}
		if (Session.get('tid')) {
			config.headers!['TenantID'] = `${Session.get('tid')}`;
		}
		if (Cookies.get('pid')) {
			config.headers!['pid'] = `${Cookies.get('pid')}`;
		}
		if (Session.get('appCode')) {
			config.headers!['appCode'] = `${Session.get('appCode')}`;
		}
		if (Cookies.get('replace_hik_token')) {
			config.headers!['replace_hik_token'] = `${Cookies.get('replace_hik_token')}`;
		}
		// if (config.url == '/v1/twins/api/init') {
		// 	config.headers!['pid'] = "2";
		// }
		return config;
	},
	(error) => {
		// 对请求错误做些什么
		return Promise.reject(error);
	}
);

// 添加响应拦截器
service.interceptors.response.use(
	(response) => {
		// 对响应数据做点什么
		const res = response.data;
		if (res instanceof Blob) {
			return response;
		}
		if (res.code && res.code !== 10000&&res.code!='Success') {
			// 34138 用户名必填
			// 34002 密码必填
			if (
				res.code === 1002 ||
				res.code === 34002 ||
				res.code === 34138 ||
				res.code === 10002 ||
				res.code === 34004 ||
				res.code === 34007
			) {
				Cookies.remove('token');
				Session.clear();
				setTimeout(() => {
					window.location.reload();
					// window.location.href = '/';
				}, 2000);
			}
			// 34007 密码错误
			// 34004 用户不存在
			// if (res.code === 401 || res.code === 34007 || res.code === 34004) {
			// 	Session.clear(); // 清除浏览器全部临时缓存
			// 	// window.location.href = '/'; // 去登录页
			// }
			//wvp平台报错
			if (res.code === 100 || res.code === 500) {
				return { code: 317 };
			}
			ElMessage.error(res.message || res.msg || 'Error');
			return { code: 317 }; // 预期结果失败
		} else {
			return res;
		}
	},
	(error) => {
		console.log(error, 'err');
		// 对响应错误做点什么
		if (error.message.indexOf('timeout') != -1) {
			ElMessage.error('网络超时');
		} else if (error.message == 'Network Error') {
			ElMessage.error('网络连接错误');
		} else {
			if (error.response.data) {
				if (error.response.data.code !== 100 && error.response.data.code !== 500) {
					ElMessage.error(error.response.statusText);
				}
			} else ElMessage.error('接口路径找不到');
		}
		return Promise.reject(error);
	}
);

// 导出 axios 实例
export default service;
