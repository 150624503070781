import request from '/@/utils/request';

// 登录
interface USER {
  username?: string
  type?: number
  password?: string
}
export function getAuthToken(data: USER): any {
  return request({
    url: '/v2.0/auth/tokens',
    method: 'post',
		data,
  });
}
// 退出登录
export function logOut(): any {
  return request({
    url: '/v2.0/auth/tokens',
    method: 'delete',
  });
}
// 域名换租户
export function getUserTid(url: string): any {
  return request({
    url: `/v2.0/auth/tenants_by_url?url=${url}`,
    method: 'get',
  });
}
// 修改用户名
export function setUser(uid: number, data: unknown): any {
  return request({
    url: `/v2.0/auth/users/${uid}`,
    method: 'put',
    data
  });
}
// 修改密码
export function setPassword(uid: number, data: unknown): any {
  return request({
    url: `/v2.0/auth/users/${uid}/newpassword`,
    method: 'put',
    data
  });
}
// 刷新用户项目信息
export function getTokenPro(params?: unknown): any {
  return request({
    url: '/v2.0/auth/refreshproject',
    method: 'get',
		params,
  });
}
