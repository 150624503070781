import { createApp } from 'vue';
import pinia from '/@/stores/index';
import App from '/@/App.vue';
import router from '/@/router';
import { directive } from '/@/directive/index';
import { i18n } from '/@/i18n/index';
import other from '/@/utils/other';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import ElementPlus from 'element-plus';
import '/@/theme/index.scss';
import VueGridLayout from 'vue-grid-layout';
import { RangePicker } from 'ant-design-vue';
import {useHouse} from '/@/utils/useHouse'
import '/@/utils/iconfont/iconfont'

(window as any).useHouse = useHouse;

const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
	app.component(key, component);
}
app.component('RangePicker', RangePicker);
directive(app);
other.elSvg(app);
app.use(pinia).use(router).use(ElementPlus).use(i18n).use(VueGridLayout).mount('#app');
